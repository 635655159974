<template>
    <v-layout wrap row ma-3>
        <v-flex>
            <v-layout align-center justify-center>
                <template v-if="badge.value">
                    <v-badge
                        top
                        right
                        :color="badge.color"
                        overlap
                        class="custom-badge"
                    >
                        <span slot="badge">{{ badge.text }}</span>
                        <v-card
                            width="500"
                            class="mx-auto my-12 custom__card"
                            rounded="15"
                            style="padding: 20px"
                            flat
                            hover
                            @click='emitClick'
                            >
                            <v-layout row wrap align-center justify-center>
                                <v-flex lg2 md2>
                                    <v-img
                                    :src="image"
                                    ></v-img>
                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-flex lg8 md8>
                                    <v-layout column wrap>
                                        <v-flex lg12 md12>
                                            <span class="title__card">{{ mensaje.title }}</span>
                                        </v-flex>
                                        <v-flex lg12 md12>
                                            <span class="subtitle__card"> {{ mensaje.subtitle }}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex>
                                    <v-icon>navigate_next</v-icon>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-badge> 
                </template>
                <template v-else>
                    <v-card
                            width="500"
                            class="mx-auto my-12 custom__card"
                            rounded="15"
                            style="padding: 10px;"
                            hover
                            flat
                            @click='emitClick'
                            >
                            <v-layout row wrap align-center justify-center>
                                <v-flex lg2 md2>
                                    <v-img
                                    :src="image"
                                    ></v-img>
                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-flex lg8 md8>
                                    <v-layout column wrap>
                                        <v-flex lg12 md12>
                                            <span class="title__card">{{ mensaje.title }}</span>
                                        </v-flex>
                                        <v-flex lg12 md12>
                                            <span class="subtitle__card"> {{ mensaje.subtitle }}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-flex>
                                    <v-icon>navigate_next</v-icon>
                                </v-flex>
                            </v-layout>
                        </v-card>
                </template>
            </v-layout>

        </v-flex>
    </v-layout>
</template>

<script>
    import { Component, Vue } from "vue-property-decorator";
	/* 
        Atención:
        - Este componente es utilizado para añadir alertas con acción
        - Puede recibir 3 props:
            1) mensaje: puede ser texto o html
            2) tipo: indica el tipo de alerta a utilizar, puede ser true 'Alerta Ok' o false 'Alerta Error'
            3) accion: este es un objeto con 2 elementos:
                * to : encargado de redireccionar a alguna URL, ej: /panel-facto/ceder-facturas
                * text : texto que aparece en el boton de accion
                * v-on:accionClick : donde se llama el componente ejecutara la accion del boton en la vista correspondiente
        EJ:
        Alerta OK
            <AlertaAccion 
                :tipo="true"
                mensaje="<strong>Certificado cargado con éxito, ahora puedes ceder tus facturas sin problemas.</strong>"
                v-on:accionClick="hiddenDiv = false"
            />
        Alerta Error
            <AlertaAccion 
                :tipo="false"
                :accion="{text:'Cargar certificado'}"
                mensaje="<strong>Para ceder tus facturas debes cargar tu certificado digital del SII (Extensión PFX)</strong>"
                v-on:accionClick="certificado = true"
           />
    */

	@Component({
		props: {
            image: String,
			mensaje : Object,
            badge: {
                type: Object,
                default: () => ({
                    value: false,
                    text: '',
                    color: '',
                }),
            },
        },
        methods:{
            emitClick(){
                this.$emit('accionClick');
            }
        },
        computed:{
            typeError(){
                if(this.$props.isWarning){
                    return 'rc-alertaAccion__warning';
                }
                else{
                    return 'rc-alertaAccion__bad';
                }
            }
        }
	})

	export default class CardOptions extends Vue {}
</script>

<style lang="scss" >
    .title__card{
        font-size: 1.25rem;
        font-weight: 700;
    }
    .subtitle__card{
        font-size: 0.875rem;
        font-weight: 400;
    }
    
    .custom__card {
        border: 1px solid white; 
        transition: border-color 0.3s;
        box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.15);
    }

    .custom__card:hover {
        border-color: #ff8a00;
    }

    .custom-badge .v-badge__badge{
        width: auto !important;
        border-radius: 20px;
        padding: 0px 8px;
        font-style: italic;
    }
</style>
